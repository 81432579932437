<template>
  <div>
    <van-field
      :name="code"
      type="textarea"
      :placeholder="item.tips ? item.tips : '请输入'"
      class="areaBox"
      maxlength="150"
      :show-word-limit="item.itemType === 5 ? false : true"
      v-model="item.itemAnswer"
      autosize
      :rules="[
        { required: true, validator: asyncValidator, message: item.itemType === 5 && item.itemAnswer ? item.tips : '' }
      ]"
      @change="change"
    >
    </van-field>
  </div>
</template>
<script>
export default {
  props: {
    item: {
      type: Object,
      default: {}
    },
    code: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      message: this.item.itemAnswer,
      reg: null,
      itemRegList: []
    }
  },
  methods: {
    change() {
      this.$nextTick(() => {
        this.$emit('changeText', this.item, this.item.itemAnswer)
      })
    },
    asyncValidator(val) {
      return new Promise((resolve) => {
        if (this.item.itemRegex && this.item.itemType === 5) {
          // console.log(this.reg.test(val));
          let flag = this.reg.test(val)
          //当最后一位是数字的时候校验长度
          let maxLength = this.itemRegList[this.itemRegList.length - 1]
          if (typeof maxLength === 'number') {
            if (val.length > maxLength) {
              flag = false
            }
          }
          resolve(flag)
        } else {
          resolve(true)
        }
      })
    }
  },
  mounted() {
    // let re = /aaa/g
    if (this.item.itemRegex && this.item.itemType === 5) {
      this.itemRegList = JSON.parse(this.item.itemRegex)
      const re = /^\/(.+)\/(.*)$/g
      const list = re.exec(this.itemRegList[0])
      this.reg = new RegExp(list[1], list[2])
      // console.log(this.reg)
    }
    this.change(this.item.itemAnswer, true)
  }
}
</script>
<style lang="less" scoped>
.areaBox {
  width: 100%;
  background: #ffffff;
  border: 1px solid #cccccc;
  border-radius: 4px;
  resize: none;
}
/deep/ .van-field {
  padding-top: 0px;
  padding-bottom: 0px;
}
// /deep/ .van-field__control{
//   color: #323233 !important;
// }
</style>