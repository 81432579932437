<template>
  <div class="allBox">
    <div>
      <!-- :rules="[{ required: true, message: '请上传' }]" -->
      <van-field :name="code">
        <template #input>
          <van-uploader
            v-model="uploader"
            :max-count="1"
            accept="image/*"
            :before-read="beforeRead"
            :afterRead="afterRead"
            capture
            @delete="remove"
            @click-upload="clickUpload"
          />
        </template>
      </van-field>
    </div>
  </div>
</template>
<script>
import { Dialog } from 'vant'
import { imageUpload } from '@/api/api.js'
import { Toast } from 'vant'
import Exif from 'exif-js'
export default {
  props: {
    item: {
      type: Object,
      default: {}
    },
    code: {
      type: String,
      default: ''
    },
    index: {
      type: Number,
      default: -1
    },
    nofatch: {
      type: Boolean,
      default: false
    },
    isHD: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      uploader: [], // 上传的文件数组
      files: {
        name: '',
        type: ''
      }, // 图片信息 用于压缩图片
      current: 0
    }
  },
  methods: {
    clickUpload() {
      this.$emit('clickImgsUpload')
    },
    // 上传前
    beforeRead(file) {
      return new Promise((resolve) => {
        this.$store.commit('changeloadingStatus', true)
        // file.status = "uploading";
        // file.message = "上传中...";
        this.files.name = file.name // 获取文件名
        this.files.type = file.type // 获取类型
        this.imgPreview(file).then((base64) => {
          resolve(this.dataURLtoFile(base64))
        })
      })
    },
    afterRead(file) {
      // 读取后file.content=undefined可以避免先渲染本地图片的问题
      if (this.nofatch !== true) {
        file.content = undefined
      }
      if (this.nofatch === true) {
        const newObj = JSON.parse(JSON.stringify(this.item))
        this.$emit('update:item', {
          ...newObj,
          absoluteUrl: file.content,
          relativeUrl: '/nofatch'
        })
        this.$emit('done', this.index)
      }
      // file.status = 'uploading'
      // file.message = '上传中...'
    },
    // 处理图片
    imgPreview(file) {
      console.log('file111: ', file.size / 1024)
      return new Promise((resolve) => {
        const self = this
        let Orientation
        // 去获取拍照时的信息，解决拍出来的照片旋转问题   npm install exif-js --save
        Exif.getData(file, function () {
          Orientation = Exif.getTag(this, 'Orientation')
        })
        // 看支持不支持FileReader
        if (!file || !window.FileReader) return
        if (/^image/.test(file.type)) {
          // 创建一个reader
          const reader = new FileReader()
          // 将图片2将转成 base64 格式
          reader.readAsDataURL(file)
          // 读取成功后的回调
          reader.onloadend = function () {
            const result = this.result
            const img = new Image()
            img.src = result
            // 判断图片是否大于500K,是就直接上传，反之压缩图片
            if (this.result.length <= 500 * 1024) {
              // 上传图片
              self.postImg(this.result)
              resolve(this.result)
            } else {
              Toast.loading({
                duration: 0, // 持续展示 toast
                forbidClick: true,
                message: '加载中',
              });
              img.onload = function () {
                const data = self.compress(img, Orientation)
                // 上传图片
                self.postImg(data)
                resolve(data)
              }
              img.onerror = function () {
                Toast.clear()
                resolve()
              }
            }
          }
        }
      })
    },
    // 压缩图片
    compress(img, Orientation) {
      const canvas = document.createElement('canvas')
      const ctx = canvas.getContext('2d')
      // 瓦片canvas
      const tCanvas = document.createElement('canvas')
      const tctx = tCanvas.getContext('2d')
      // let initSize = img.src.length;
      let width = img.width
      let height = img.height
      // 如果图片大于四百万像素，计算压缩比并将大小压至400万以下
      let ratio
      if ((ratio = (width * height) / 4000000) > 1 && !this.isHD) {
        // console.log("大于400万像素");
        ratio = Math.sqrt(ratio)
        width /= ratio
        height /= ratio
      } else {
        ratio = 1
      }
      canvas.width = width
      canvas.height = height
      //    铺底色
      ctx.fillStyle = '#fff'
      ctx.fillRect(0, 0, canvas.width, canvas.height)
      // 如果图片像素大于100万则使用瓦片绘制
      let count
      if ((count = (width * height) / 1000000) > 1) {
        // console.log("超过100W像素");
        count = ~~(Math.sqrt(count) + 1) // 计算要分成多少块瓦片
        //      计算每块瓦片的宽和高
        const nw = ~~(width / count)
        const nh = ~~(height / count)
        tCanvas.width = nw
        tCanvas.height = nh
        for (let i = 0; i < count; i++) {
          for (let j = 0; j < count; j++) {
            tctx.drawImage(img, i * nw * ratio, j * nh * ratio, nw * ratio, nh * ratio, 0, 0, nw, nh)
            ctx.drawImage(tCanvas, i * nw, j * nh, nw, nh)
          }
        }
      } else {
        ctx.drawImage(img, 0, 0, width, height)
      }
      // 修复ios上传图片的时候 被旋转的问题
      // console.log('Orientation IOS旋转',Orientation);
      if (Orientation != '' && Orientation != 1) {
        switch (Orientation) {
          // case 6: //需要顺时针（向左）90度旋转
          //   this.rotateImg(img, "left", canvas);
          //   break;
          case 8: // 需要逆时针（向右）90度旋转
            this.rotateImg(img, 'right', canvas)
            break
          case 3: // 需要180度旋转
            this.rotateImg(img, 'right', canvas) // 转两次
            this.rotateImg(img, 'right', canvas)
            break
        }
      }
      // 进行最小压缩
      const quality = this.isHD ? 1 : 0.5
      const ndata = canvas.toDataURL('image/jpeg', quality)
      tCanvas.width = tCanvas.height = canvas.width = canvas.height = 0
      // console.log(ndata);
      return ndata
    },
    // 旋转图片
    rotateImg(img, direction, canvas) {
      // 最小与最大旋转方向，图片旋转4次后回到原方向
      const min_step = 0
      const max_step = 3
      if (img == null) return
      // img的高度和宽度不能在img元素隐藏后获取，否则会出错
      const height = img.height
      const width = img.width
      let step = 2
      if (step == null) {
        step = min_step
      }
      if (direction == 'right') {
        step++
        // 旋转到原位置，即超过最大值
        step > max_step && (step = min_step)
      } else {
        step--
        step < min_step && (step = max_step)
      }
      // 旋转角度以弧度值为参数
      const degree = (step * 90 * Math.PI) / 180
      const ctx = canvas.getContext('2d')
      switch (step) {
        case 0:
          canvas.width = width
          canvas.height = height
          ctx.drawImage(img, 0, 0)
          break
        case 1:
          canvas.width = height
          canvas.height = width
          ctx.rotate(degree)
          ctx.drawImage(img, 0, -height)
          break
        case 2:
          canvas.width = width
          canvas.height = height
          ctx.rotate(degree)
          ctx.drawImage(img, -width, -height)
          break
        case 3:
          canvas.width = height
          canvas.height = width
          ctx.rotate(degree)
          ctx.drawImage(img, -width, 0)
          break
      }
    },
    // 将base64转换为文件
    dataURLtoFile(dataurl) {
      var arr = dataurl.split(',')
      var bstr = atob(arr[1])
      var n = bstr.length
      var u8arr = new Uint8Array(n)
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n)
      }
      return new File([u8arr], this.files.name, {
        type: this.files.type
      })
    },
    // 提交图片到后端
    postImg(base64) {
      // 添加不需要向后端发送请求的拦截props  用于iframe嵌入
      if (this.nofatch === true) {
        return
      }
      const file = this.dataURLtoFile(base64)
      console.log('file2222: ', file.size / 1024)
      const formData = new window.FormData()
      formData.append('file', file)
      // console.log(file);
      // 提交图片 调用接口
      // Some code
      let name = file.name
      name = name.substring(name.lastIndexOf('.') + 1)
      formData.append('format', name)
      formData.append('module', 'collection')
      formData.append('scene', 'task')
      formData.append('terminalName', this.$store.state.userInfo.terminalName) // 打开后端添加水印
      imageUpload(formData)
        .then((res) => {
          if (res.data.success) {
            this.uploader[0].reUrl = res.data.data.fileUrl
            // this.uploader[0].content = res.data.data.absoluteFileUrl;//组件内默认content设置为undefined之后就取url所以不改content也可以
            this.uploader[0].url = res.data.data.absoluteFileUrl // 打开可立即展示水印后的图片 暂时不需要会造成内存压力大
            const newObj = JSON.parse(JSON.stringify(this.item))
            this.$emit('update:item', {
              ...newObj,
              // 20230206 移除itemImg字段全部使用 itemImgs
              // itemImg: res.data.data.fileUrl,
              absoluteUrl: res.data.data.absoluteFileUrl,
              relativeUrl: res.data.data.fileUrl
            })
            this.uploader[0].status = 'done'
            this.$emit('myDisabled', this.code, false)
            this.$emit('done', this.index)
          } else {
            this.uploader[0].status = 'failed'
            Dialog.alert({
              title: '提示',
              message: res.data.errorMessage
            })
            this.uploader = []
          }
          this.$store.commit('changeloadingStatus', false)
        })
        .catch(() => {
          this.uploader[0].status = 'failed'
          this.$store.commit('changeloadingStatus', false)
          Dialog.alert({
            title: '提示',
            message: '图片上传失败请重新上传'
          })
          this.uploader = []
        })
    },
    // 点击删除
    remove() {
      const newObj = JSON.parse(JSON.stringify(this.item))
      this.$emit('update:item', {
        ...newObj,
        // 20230206 移除itemImg字段全部使用 itemImgs
        // itemImg: '',
        absoluteUrl: '',
        relativeUrl: ''
      })
      this.$emit('remove', this.index)
    }
  },
  created() {
    // 单图组件反显图片
    // 20230206 移除itemImg字段全部使用 itemImgs
    // if (this.item.itemImg !== null && this.item.itemImg !== undefined) {
    //   this.uploader = [{ url: this.item.itemImg }]
    // }
    // 多图组件反显
    if (this.item.absoluteUrl && this.item.relativeUrl) {
      if (this.nofatch === true) {
        // 兼容不发请求的时候的反显
        this.uploader = [{ content: this.item.absoluteUrl }]
      } else {
        this.uploader = [{ url: this.item.absoluteUrl }]
      }
    }
  }
}
</script>
<style lang="less" scoped>
/deep/ .van-field {
  padding: 0px 23px;
}
.allBox {
  position: relative;
  width: 100%;
  .example {
    display: flex;
    align-items: center;
    font-size: 14px;
    color: #333333;
    .arrow {
      position: relative;
      top: 2px;
      margin-left: 13px;
    }
  }
}
</style>
