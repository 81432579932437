<template>
  <div class="out">
    <!-- {{item.itemImgs}} -->
    <UploadField
      :nofatch="nofatch"
      @myDisabled="myDisabled"
      @notChange="notChange"
      v-for="(data, index) in item.itemImgs"
      :key="data.key"
      :code="item.code"
      :item.sync="item.itemImgs[index]"
      :index="index"
      :isHD="item.tips === '高清'"
      @remove="remove"
      @done="done"
      :class="index >= item.imgMinCount ? 'extra' : ''"
      @clickImgsUpload="$emit('clickImgsUpload')"
    />
  </div>
</template>
<script>
import UploadField from './UploadField.vue'
export default {
  components: { UploadField },
  props: {
    item: {
      type: Object,
      default: {}
    },
    code: {
      type: String,
      default: ''
    },
    nofatch: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      keyAdd: 0
    }
  },
  methods: {
    myDisabled(code, flag) {
      this.$emit('myDisabled', code, flag)
    },
    notChange() {
      this.$emit('notChange')
    },
    getValid(list) {
      let valid = 0
      list.forEach((element) => {
        if (element.relativeUrl) {
          valid++
        }
      })
      return valid
    },
    remove(index) {
      // 230302 因任务级联需要保存答题状态 取消深拷贝
      // let newItem = JSON.parse(JSON.stringify(this.item))
      const newItem = this.item
      if (newItem.itemImgs.length > this.item.imgMinCount) {
        newItem.itemImgs.splice(index, 1)
        // 添加dashed项
        if (
          newItem.itemImgs.length < this.item.imgMaxCount &&
          newItem.itemImgs.length >= this.item.imgMinCount &&
          newItem.itemImgs[newItem.itemImgs.length - 1].relativeUrl
        ) {
          this.keyAdd++
          newItem.itemImgs.push({
            relativeUrl: null,
            absoluteUrl: null,
            key: 'add' + new Date().getTime()
          })
        }
        this.$emit('update:item', newItem)
        this.$emit('clickImgsUpload')
      }
    },
    done() {
      // 230302 因任务级联需要保存答题状态 取消深拷贝
      // let newItem = JSON.parse(JSON.stringify(this.item))
      const newItem = this.item
      const valid = this.getValid(newItem.itemImgs)
      if (
        valid >= this.item.imgMinCount &&
        valid < this.item.imgMaxCount &&
        newItem.itemImgs[newItem.itemImgs.length - 1].relativeUrl
      ) {
        this.keyAdd++
        newItem.itemImgs.push({
          relativeUrl: null,
          absoluteUrl: null,
          key: 'add' + new Date().getTime()
        })
        this.$emit('update:item', newItem)
      }
    }
  },
  mounted() {
    this.done()
  }
}
</script>
<style lang="less" scoped>
.out {
  display: flex;
  flex-wrap: wrap;
  padding-left: 23px;
}
/deep/ .allBox {
  width: auto;
}
/deep/ .van-field {
  padding: 0;
}
.extra {
  /deep/ .van-uploader__upload {
    border: 1px dashed #ccc;
  }
}
</style>
