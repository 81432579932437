<template>
  <div>
    <!-- :rules="[{ required: true, message: '请选择' }]" -->
    <van-field :name="code">
      <template #input>
        <van-radio-group v-model="item.itemOptionCode" @change="change">
          <van-radio v-for="(data, index) in item.subTaskListOptionDetailDto" :key="index" :name="data.code">
            {{ data.title }}
          </van-radio>
        </van-radio-group>
      </template>
    </van-field>
  </div>
</template>
<script>
export default {
  props: {
    item: {
      type: Object,
      default: {}
    },
    code: {
      type: String,
      default: ''
    },
    index: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      radio: '1'
    }
  },
  methods: {
    change(val) {
      this.$emit('changeRadio', this.item, val, this.index)
    }
  },
  mounted() {}
}
</script>
<style lang="less" scoped>
/deep/ .van-radio {
  margin-bottom: 20px;
}
/deep/ .van-radio:nth-last-child(1) {
  margin-bottom: 0px;
}
/deep/ .van-field {
  padding: 0px 23px;
}
</style>