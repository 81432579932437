<template>
  <div>
    <!-- :rules="[{ required: true, message: '请选择' }]" -->
    <van-field :name="code">
      <template #input>
        <van-checkbox-group v-model="checkboxGroup" @change="change">
          <van-checkbox
            shape="square"
            v-for="(data, index) in item.subTaskListOptionDetailDto"
            :key="index"
            :name="data.code"
          >
            {{ data.title }}
          </van-checkbox>
        </van-checkbox-group>
      </template>
    </van-field>
  </div>
</template>
<script>
export default {
  props: {
    item: {
      type: Object,
      default: {}
    },
    itemOptionCode: {
      type: String,
      default: ''
    },
    code: {
      type: String,
      default: ''
    },
    index: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      checkbox: false,
      checkboxGroup: []
    }
  },
  methods: {
    change(val) {
      let obj = this.item
      obj.itemOptionCode = val.join('#')
      this.$emit('update:item', obj)
      this.$emit('changeCheck', obj, obj.itemOptionCode, this.index)
    }
  },
  mounted() {
    if (this.item.itemOptionCode) {
      this.checkboxGroup = this.item.itemOptionCode.split('#')
    } else {
      this.checkboxGroup = []
    }
  },
  watch: {
    itemOptionCode: function (val) {
      console.log('val1111: ', val)
      if (val) {
        this.checkboxGroup = val.split('#')
      } else {
        this.checkboxGroup = []
      }
    }
  }
}
</script>
<style lang="less" scoped>
/deep/ .van-checkbox {
  margin-bottom: 20px;
}
/deep/ .van-checkbox:nth-last-child(1) {
  margin-bottom: 0px;
}
/deep/ .van-field {
  padding: 0px 23px;
}
</style>
